var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "search-attr" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticStyle: { width: "170px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "用户昵称 | user_id"
                },
                model: {
                  value: _vm.params.keyword,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "keyword", $$v)
                  },
                  expression: "params.keyword"
                }
              }),
              _c(
                "a-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "会员类型"
                  },
                  model: {
                    value: _vm.params.payType,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "payType", $$v)
                    },
                    expression: "params.payType"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("普通分享码兑换")
                  ]),
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("小程序付款")
                  ]),
                  _c("a-select-option", { attrs: { value: 10 } }, [
                    _vm._v("代理商推广码兑换")
                  ]),
                  _c("a-select-option", { attrs: { value: 20 } }, [
                    _vm._v("下级充值赠送")
                  ])
                ],
                1
              ),
              _c(
                "a-select",
                {
                  staticStyle: { width: "150px", "margin-left": "10px" },
                  attrs: {
                    allowClear: "",
                    size: "small",
                    placeholder: "订单状态"
                  },
                  model: {
                    value: _vm.params.status,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status"
                  }
                },
                [
                  _c("a-select-option", { attrs: { value: 0 } }, [
                    _vm._v("待付款")
                  ]),
                  _c("a-select-option", { attrs: { value: 1 } }, [
                    _vm._v("待发货")
                  ]),
                  _c("a-select-option", { attrs: { value: -1 } }, [
                    _vm._v("已取消")
                  ]),
                  _c("a-select-option", { attrs: { value: -10 } }, [
                    _vm._v("已退款")
                  ])
                ],
                1
              ),
              _c("a-date-picker", {
                staticStyle: { width: "140px", "margin-left": "5px" },
                attrs: {
                  allowClear: "",
                  placeholder: "支付时间",
                  size: "small"
                },
                model: {
                  value: _vm.params.payTime,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "payTime", $$v)
                  },
                  expression: "params.payTime"
                }
              }),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { size: "small" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              )
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.cropModal()
                }
              }
            },
            [_vm._v("裁剪效果")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "setup",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openModal(row)
                          }
                        }
                      },
                      [_vm._v("查看详情")]
                    )
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 1000,
            title: "效果",
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.cancelForm, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.goods } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "a-upload",
                    {
                      key: _vm.imgKey,
                      attrs: {
                        action: _vm.ip + "/file/upload",
                        "list-type": "picture-card",
                        "file-list": _vm.fileList
                      },
                      on: { change: _vm.handleChange }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _c("div", { staticClass: "ant-upload-text" }, [
                            _vm._v("上传图片")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.cutImage()
                            }
                          }
                        },
                        [_vm._v("裁剪")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.cropUrl
                ? _c("a-form-model-item", { attrs: { label: "裁剪后" } }, [
                    _c("img", {
                      staticClass: "crop-image",
                      attrs: { src: _vm.cropUrl }
                    })
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 1000, centered: "", footer: null },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _vm.detailVo.payType == 0
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v("会员类型： "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("普通分享码兑换")
                      ])
                    ])
                  : _vm._e(),
                _vm.detailVo.payType == 1
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v("会员类型： "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("小程序付款")
                      ])
                    ])
                  : _vm._e(),
                _vm.detailVo.payType == 10
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v("会员类型："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("代理商推广码兑换")
                      ])
                    ])
                  : _vm._e(),
                _vm.detailVo.payType == 20
                  ? _c("div", { staticClass: "title" }, [
                      _vm._v("会员类型："),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("下级充值赠送")
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "下单用户：" +
                        _vm._s(_vm.detailVo.userName) +
                        "(" +
                        _vm._s(_vm.detailVo.userId) +
                        ")"
                    )
                  ]),
                  _vm.detailVo.sourceUserId
                    ? _c("div", { staticClass: "basic-item" }, [
                        _vm._v(
                          "推广用户：" +
                            _vm._s(_vm.detailVo.sourceUserName) +
                            "(" +
                            _vm._s(_vm.detailVo.sourceUserId) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "modal-content" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "兑换/支付时间：" + _vm._s(_vm.detailVo.payTime || "")
                    )
                  ]),
                  _vm.detailVo.sourceCode
                    ? _c("div", { staticClass: "basic-item" }, [
                        _vm._v(
                          "兑换/推广码：" +
                            _vm._s(_vm.detailVo.sourceCode || "")
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("会员天数：" + _vm._s(_vm.detailVo.days))
                  ])
                ])
              ]),
              _vm.detailVo.payType == 1 &&
              (_vm.detailVo.status == 1 || _vm.detailVo.status == -10)
                ? _c("div", { staticClass: "modal-items" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("付费信息")]),
                    _c("div", { staticClass: "modal-content modal-express" }, [
                      _vm.detailVo.status == -1
                        ? _c("div", { staticClass: "express" }, [
                            _vm._v("订单状态: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(" 已取消")
                            ])
                          ])
                        : _vm._e(),
                      _vm.detailVo.status == 0
                        ? _c("div", { staticClass: "express" }, [
                            _vm._v("订单状态: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(" 未支付")
                            ])
                          ])
                        : _vm._e(),
                      _vm.detailVo.status == 1
                        ? _c("div", { staticClass: "express" }, [
                            _vm._v("订单状态: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(" 已付款")
                            ])
                          ])
                        : _vm._e(),
                      _vm.detailVo.status == -10
                        ? _c("div", { staticClass: "express" }, [
                            _vm._v("订单状态: "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(" 已退款")
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "express" }, [
                        _vm._v("订单金额："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("￥" + _vm._s(_vm.detailVo.money))
                        ])
                      ]),
                      _c("div", { staticClass: "express" }, [
                        _vm._v("支付金额："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("￥" + _vm._s(_vm.detailVo.payMoney))
                        ])
                      ]),
                      _c("div", { staticClass: "express" }, [
                        _vm._v("商户订单号："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("￥" + _vm._s(_vm.detailVo.requestNo || ""))
                        ])
                      ]),
                      _vm.detailVo.refundMoney > 0
                        ? _c("div", { staticClass: "express" }, [
                            _vm._v("退款金额："),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "￥" + _vm._s(_vm.detailVo.refundMoney || "")
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.detailVo.status == 1
                        ? _c(
                            "div",
                            { staticClass: "modal-content" },
                            [
                              _c("a-textarea", {
                                staticStyle: {
                                  width: "200px",
                                  "margin-bottom": "10px",
                                  "margin-right": "10px"
                                },
                                attrs: {
                                  placeholder: "请输入退款金额",
                                  "auto-size": ""
                                },
                                model: {
                                  value: _vm.refundMoneyText,
                                  callback: function($$v) {
                                    _vm.refundMoneyText = $$v
                                  },
                                  expression: "refundMoneyText"
                                }
                              }),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modifyRefundMoney(_vm.id)
                                    }
                                  }
                                },
                                [_vm._v("退款金额")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "express" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确认主动退款么?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.activeRefund(_vm.detailVo.id)
                                }
                              }
                            },
                            [
                              _vm.detailVo.status == 1 &&
                              _vm.detailVo.refundMoney > 0
                                ? _c(
                                    "a-button",
                                    { attrs: { type: "primary" } },
                                    [_vm._v("退款")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("操作日志")]),
                _c("div", {
                  staticClass: "modal-content",
                  domProps: { innerHTML: _vm._s(_vm.detailVo.reviewMsg) }
                })
              ])
            ]
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 500, centered: "", footer: null },
          model: {
            value: _vm.snapshotshow,
            callback: function($$v) {
              _vm.snapshotshow = $$v
            },
            expression: "snapshotshow"
          }
        },
        [
          _c(
            "div",
            { style: "max-height:" + _vm.ModalHeight + "px;overflow:auto;" },
            [
              _c("div", { staticClass: "modal-items" }, [
                _c("div", { staticClass: "title" }, [_vm._v("快照信息")]),
                _c("div", { staticClass: "modal-content modal-express" }, [
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品编号：" + _vm._s(_vm.productVersion.productNo))
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v(
                      "商品名称：" +
                        _vm._s(_vm.productVersion.productName || "")
                    )
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("商品价格："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.finalPrice))
                    ])
                  ]),
                  _c("div", { staticClass: "basic-item" }, [
                    _vm._v("运费："),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("￥" + _vm._s(_vm.productVersion.logisticsFee))
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "express" },
                    [
                      _c("span", [_vm._v("图片：")]),
                      _vm._l(_vm.productVersion.files, function(item, key) {
                        return _c("img", {
                          key: key,
                          staticStyle: {
                            "max-width": "50px",
                            "max-height": "50px",
                            "margin-right": "5px"
                          },
                          attrs: { src: item },
                          on: {
                            click: function($event) {
                              return _vm.previewImg(_vm.productVersion.files)
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.previewVisible,
              expression: "previewVisible"
            }
          ],
          staticClass: "preview-image"
        },
        [
          _c(
            "div",
            {
              staticClass: "preview-image-close",
              on: {
                click: function($event) {
                  _vm.previewVisible = false
                }
              }
            },
            [_vm._v(" 关闭 ")]
          ),
          _c("img", { attrs: { alt: "example", src: _vm.previewImage } })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }