<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input
          allowClear
          v-model="params.keyword"
          size='small'
          style="width:170px"
          placeholder='用户昵称 | user_id'
        ></a-input>

        <a-select
          allowClear
          v-model="params.payType"
          size='small'
          style="width:150px;margin-left:10px;"
          placeholder='会员类型'
        >
          <a-select-option :value='0'>普通分享码兑换</a-select-option>
          <a-select-option :value='1'>小程序付款</a-select-option>
          <a-select-option :value='10'>代理商推广码兑换</a-select-option>
          <a-select-option :value='20'>下级充值赠送</a-select-option>
        </a-select>

        <a-select
          allowClear
          v-model="params.status"
          size='small'
          style="width:150px;margin-left:10px;"
          placeholder='订单状态'
        >
          <a-select-option :value='0'>待付款</a-select-option>
          <a-select-option :value='1'>待发货</a-select-option>
          <a-select-option :value='-1'>已取消</a-select-option>
          <a-select-option :value='-10'>已退款</a-select-option>
        </a-select>

        <a-date-picker
          allowClear
          v-model="params.payTime"
          placeholder="支付时间"
          size='small'
          style="width:140px;margin-left:5px;"
        />
        <a-button
          @click="search"
          size='small'
          style="margin-left:10px;"
        >搜索</a-button>
      </div>

      <a-button
        @click="cropModal()"
        type="primary"
      >裁剪效果</a-button>

    </div>
    <div class="table-content">
      <a-table
        @change="changePage"
        :pagination='pagination'
        :loading='loading'
        :columns="columns"
        :data-source="data"
        :rowKey="(record,n)=>n"
      >
        <div
          slot="setup"
          slot-scope="row"
        >
          <a @click="openModal(row)">查看详情</a>
        </div>
      </a-table>
    </div>

    <a-modal
      :width="1000"
      v-model="visible"
      title="效果"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelForm"
      @ok="hideModal"
    >
      <a-form-model
        ref="ruleForm"
        :model="goods"
        v-bind="layout"
      >

        <a-form-model-item label="图片">
          <a-upload
            :key="imgKey"
            :action="ip + '/file/upload'"
            @change="handleChange"
            list-type="picture-card"
            :file-list="fileList"
          >
            <div>
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <div>
            <a-button @click="cutImage()">裁剪</a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="裁剪后"
          v-if="cropUrl"
        >
          <img
            class="crop-image"
            :src="cropUrl"
          >
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      v-model="show"
      :width='1000'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div
            class="title"
            v-if="detailVo.payType == 0"
          >会员类型： <span style="color:red">普通分享码兑换</span></div>
          <div
            class="title"
            v-if="detailVo.payType == 1"
          >会员类型： <span style="color:red">小程序付款</span></div>
          <div
            class="title"
            v-if="detailVo.payType == 10"
          >会员类型：<span style="color:red">代理商推广码兑换</span></div>
          <div
            class="title"
            v-if="detailVo.payType == 20"
          >会员类型：<span style="color:red">下级充值赠送</span></div>

          <div class="modal-content">
            <div class="basic-item">下单用户：{{detailVo.userName}}({{detailVo.userId}})</div>
            <div
              class="basic-item"
              v-if="detailVo.sourceUserId"
            >推广用户：{{detailVo.sourceUserName}}({{detailVo.sourceUserId}})</div>
          </div>

          <div class="modal-content">
            <div class="basic-item">兑换/支付时间：{{detailVo.payTime||''}}</div>
            <div
              class="basic-item"
              v-if="detailVo.sourceCode"
            >兑换/推广码：{{detailVo.sourceCode||''}}</div>
            <div class="basic-item">会员天数：{{detailVo.days}}</div>
          </div>
        </div>

        <div
          class="modal-items"
          v-if="detailVo.payType == 1 &&(detailVo.status == 1 || detailVo.status == -10)"
        >
          <div class="title">付费信息</div>
          <div class="modal-content modal-express">
            <div
              class="express"
              v-if="detailVo.status == -1"
            >订单状态: <span style="color:red"> 已取消</span></div>
            <div
              class="express"
              v-if="detailVo.status == 0"
            >订单状态: <span style="color:red"> 未支付</span></div>
            <div
              class="express"
              v-if="detailVo.status == 1"
            >订单状态: <span style="color:red"> 已付款</span></div>
            <div
              class="express"
              v-if="detailVo.status == -10"
            >订单状态: <span style="color:red"> 已退款</span></div>

            <div class="express">订单金额：<span style="color:red">￥{{detailVo.money}}</span></div>
            <div class="express">支付金额：<span style="color:red">￥{{detailVo.payMoney}}</span></div>
            <div class="express">商户订单号：<span style="color:red">￥{{detailVo.requestNo||''}}</span></div>
            <div
              class="express"
              v-if="detailVo.refundMoney > 0"
            >退款金额：<span style="color:red">￥{{detailVo.refundMoney||''}}</span></div>

            <div
              class="modal-content"
              v-if="detailVo.status == 1"
            >
              <a-textarea
                v-model="refundMoneyText"
                style="width:200px;margin-bottom:10px;margin-right:10px;"
                placeholder="请输入退款金额"
                auto-size
              />
              <a-button
                type='primary'
                @click="modifyRefundMoney(id)"
              >退款金额</a-button>
            </div>

            <div class="express">
              <a-popconfirm
                title="确认主动退款么?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="activeRefund(detailVo.id)"
              >
                <a-button
                  v-if="detailVo.status == 1 && detailVo.refundMoney > 0"
                  type='primary'
                >退款</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>

        <div class="modal-items">
          <div class="title">操作日志</div>

          <div
            class="modal-content"
            v-html="detailVo.reviewMsg"
          ></div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="snapshotshow"
      :width='500'
      centered
      :footer='null'
    >
      <div :style="'max-height:'+ModalHeight+'px;overflow:auto;'">
        <div class="modal-items">
          <div class="title">快照信息</div>

          <div class="modal-content modal-express">

            <div class="basic-item">商品编号：{{productVersion.productNo}}</div>
            <div class="basic-item">商品名称：{{productVersion.productName||''}}</div>
            <div class="basic-item">商品价格：<span style="color:red">￥{{productVersion.finalPrice}}</span></div>
            <div class="basic-item">运费：<span style="color:red">￥{{productVersion.logisticsFee}}</span></div>
            <div class="express">
              <span>图片：</span>
              <img
                @click="previewImg(productVersion.files)"
                v-for='(item,key) of productVersion.files'
                :key="key"
                style="max-width:50px;max-height:50px;margin-right:5px;"
                :src='item'
              >
            </div>

          </div>
        </div>
      </div>
    </a-modal>
    <!-- 预览图片 -->
    <div
      class="preview-image"
      v-show="previewVisible"
    >
      <div
        class="preview-image-close"
        @click="previewVisible = false"
      >
        关闭
      </div>
      <img
        alt="example"
        :src="previewImage"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      columns: [
        { title: "用户", dataIndex: "userName" },
        {
          title: "会员类型",
          dataIndex: "payType",
          customRender: (text) => {
            const inner = text;
            if (inner == 0) {
              return <span style="color:green">普通分享码兑换</span>;
            }
            if (inner == 1) {
              return <span style="color:red">小程序付款</span>;
            }
            if (inner == 10) {
              return <span style="color:blue">代理商推广码兑换</span>;
            }
            if (inner == 20) {
              return <span style="color:brown">下级充值赠送</span>;
            }
          },
        },
        {
          title: "支付状态",
          dataIndex: "status",
          customRender: (text) => {
            const inner = text;
            if (inner == 0) {
              return <span style="color:black">未支付</span>;
            }
            if (inner == 1) {
              return <span style="color:green">已支付</span>;
            }
            if (inner == -1) {
              return <span style="color:red">已取消</span>;
            }
            if (inner == -10) {
              return <span style="color:red">已退款</span>;
            }
          },
        },
        { title: "支付金额", align: "center", dataIndex: "payMoney" },
        { title: "支付时间", dataIndex: "payTime" },
        { title: "会员天数", dataIndex: "days" },
        { title: "推广人", dataIndex: "sourceUserName" },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      visible: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
        payType: null,
        payTime: "",
      },
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      orderState: [
        { label: "已取消", value: -1 },
        { label: "待付款", value: 0 },
        { label: "已付款", value: 1 },
        { label: "已退款", value: -10 },
      ],
      loading: false,
      show: false,
      snapshotshow: false,
      orderProductInfoVo: {},
      orderLogisticsVo: {},
      orderLogVo: [],
      detailVo: {},
      ModalHeight: null,
      refundMoneyText: "",
      orderId: "",
      productVersion: {},
      visible: false,
      previewImage: "",
      previewVisible: false,
      fileList: [],
      coverList: [],
      goods: {},
      imgKey: "",
      imageUrl: "",
      cropUrl: "",
    };
  },

  created() {
    this.getData(this.params);
    const { innerHeight } = window;
    this.ModalHeight = innerHeight - 100;
  },
  computed: {
    ...mapState(["ip"]),
    showExpress() {
      const { refundStatus = null } = this.orderProductInfoVo;
      if (refundStatus != null) {
        return true;
      } else {
        return false;
      }
    },
    showSet() {
      const { refundStatus = null } = this.orderProductInfoVo;
      if (refundStatus === -10) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/dq_admin/silverVip/list", {
        params: params,
      });
      this.loading = false;
      const { records, total } = res.data;
      this.data = records;
      this.pagination.total = total;
    },
    search() {
      if (this.params.createTime) {
        const time = new Date(this.params.createTime)
          .toLocaleDateString()
          .replace(/\//g, "-");
        if (time && time.length > 0) {
          this.params.createTime = time;
        }
      }
      if (this.params.payTime) {
        const payTimeResult = new Date(this.params.payTime)
          .toLocaleDateString()
          .replace(/\//g, "-");
        // const payTimeResult = this.dateTypeFormat(this.params.payTime).Format('YYYY-mm-dd').toLocaleDateString().replace(/\//g,"-")
        if (payTimeResult && payTimeResult.length > 0) {
          this.params.payTime = payTimeResult;
        }
      }

      this.params.pageNum = 1;
      this.getData(this.params);
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.limit = pageSize;
      this.getData(this.params);
    },
    openModal(row) {
      this.show = true;
      const { id } = row;
      this.orderId = id;
      this.detailVo = JSON.parse(JSON.stringify(row));
      this.getDetail(this.orderId);
    },
    async getDetail(orderId) {
      const res = await this.axios("/dq_admin/silverVip/getDetail", {
        params: { orderId },
      });
      if (res.status == 200) {
        this.detailVo = res.data;
      }
    },
    async activeRefund(orderId) {
      if (!orderId) return this.$message.warning("orderId");
      const params = { orderId: this.orderId };
      const res = await this.axios.post("/dq_admin/silverVip/refund", params);
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.getDetail(this.orderId);
        this.getData(this.params);
      }
    },

    openModalSnapshot(orderId) {
      this.snapshotshow = true;
      this.orderId = orderId;
      this.getSnapshot(this.orderId);
    },

    async getSnapshot(orderId) {
      const res = await this.axios("/dq_admin/orderProduct/getOrderSnapshot", {
        params: { orderId },
      });
      if (res.status == 200) {
        const { productVersion } = res.data;
        this.productVersion = productVersion;
      }
    },

    async modifyRefundMoney(orderId) {
      const params = {
        orderId: this.orderId,
        refundMoney: this.refundMoneyText,
      };
      const res = await this.axios.post(
        "/dq_admin/silverVip/modifyRefundMoney",
        params
      );
      if (res.status == 200) {
        const { message } = res;
        this.$message.success(message);
        this.refundMoneyText = "";
        this.getDetail(this.orderId);
      }
    },

    previewImg(list) {
      if (row.list && list.length > 0) {
        this.$previewImg({
          list: list.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
        });
      }
    },

    cropModal() {
      this.visible = true;
    },

    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },

    async cutImage() {
      try {
        const images = this.fileList.map((row) => {
          return row.url;
        });
        this.imageUrl = images.join(",");
      } catch (e) {}
      this.$loading.show();
      const res = await this.axios.get(
        "/dq_admin/customCategory/cutImage?imageUrl=" + this.imageUrl
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message || "响应成功");
      var pictureUrl = res.data.data.pictureUrl;
      var newCoverUrl = res.data.data.newCoverUrl;
      // this.visible = false;
      this.cropUrl = newCoverUrl;
      this.previewImage = newCoverUrl;
      if (newCoverUrl) {
        this.previewVisible = true;
      }
      // handleChange();
      // this.$emit("success");
      // this.$emit("close");
    },

    resetForms() {
      this.forms = {};
      this.planType = 1;
      this.$refs.ruleForm.resetFields();
      this.fileList = [];
    },

    hideModal(type) {
      this.fileList = [];
      this.visible = false;
      this.previewVisible = false
      this.cropUrl = "";
      this.previewImage = "";
    },

    cancelForm() {
      this.fileList = [];
      this.visible = false;
      this.previewVisible = false
      this.cropUrl = "";
      this.previewImage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.modal-items {
  width: 100%;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-weight: bold;
  }
  .modal-content {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    .basic-item {
      margin-right: 50px;
      margin-bottom: 20px;
    }
  }
  .modal-express {
    display: flex;
    flex-direction: column;
    .express {
      margin-bottom: 20px;
    }
    .order-log {
      margin-bottom: 20px;
    }
  }
}

.crop-image {
  width: 104px;
  height: 104px;
}

.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>